import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import {
  graphik,
  mainWhite,
  larsseit,
  mainGray,
  mainBlue,
  mainBlack,
  lightGray,
  transHover,
  screen,
} from "../../components/common/variables";
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.footer`
  display: none;
  @media ${screen.xsmall} {
    color: ${mainBlack};
    font-family: ${graphik};
    background-color: ${lightGray};
    display: block;
    width: 100%;
  }

  .contact-details {
    display: flex;
    max-width: 1160px;
    margin: 0 auto;
    @media ${screen.xsmall} {
      padding: 80px 60px;
    }
    @media ${screen.small} {
      padding: 90px 80px;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }
    @media ${screen.xlarge} {
      padding: 120px 0;
    }

    .each-col {
      width: 33.33%;
      margin: 0 0 0 50px;
      @media ${screen.small} {
        width: 30%;
      }
      @media ${screen.medium} {
        width: 33.33%;
      }
      &:first-child {
        margin: 0;
        @media ${screen.small} {
          width: 40%;
        }
        @media ${screen.medium} {
          width: 33.33%;
        }
      }

      &--desktop {
        display: none;
        @media ${screen.medium} {
          display: block;
          width: 28%;
        }
      }

      &__lg-heading {
        display: block;
        font-family: ${larsseit};
        font-weight: 500;

        @media ${screen.xsmall} {
          font-size: 2.3rem;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: calc(2.5rem, calc(0.9rem + 2vw), 3.31rem);
        }
      }

      &__sm-heading {
        display: table;
        font-weight: 500;
        line-height: 1.6;
        @media ${screen.xsmall} {
          font-size: 1rem;
          margin-bottom: 7px;
        }
        @media ${screen.medium} {
          font-size: clamp(0.9rem, calc(0.52rem + 0.5vw), 1.12rem);
          margin-bottom: 12px;
        }

        &--phone {
          @media ${screen.xsmall} {
            margin-top: 36px;
          }
          @media ${screen.medium} {
            margin-top: 0;
          }
        }
      }

      &__link {
        color: ${mainBlack};
        display: table;
        white-space: pre-line;
        line-height: 1.5;
        transition: ${transHover};
        @media ${screen.medium} {
          font-size: clamp(0.9rem, calc(0.52rem + 0.5vw), 1.12rem);
          margin: 0 0 30px 0;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainBlue};
          }
        }
      }

      &__social-list {
        display: flex;
        align-items: center;
        @media ${screen.xsmall} {
          margin: 36px 0 0 0;
        }
        @media ${screen.medium} {
          justify-content: space-between;
          margin: 0;
        }

        .social-item {
          a {
            color: ${mainGray};
            display: block;
            transition: ${transHover};
            @media ${screen.xsmall} {
              font-size: 2.1rem;
              margin: 0 20px 0 0;
            }
            @media ${screen.medium} {
              font-size: clamp(1.8rem, calc(0.8rem + 1vw), 2rem);
              margin: 0;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainBlue};
              }
            }
          }
        }

        &--tablet {
          @media ${screen.medium} {
            display: none;
          }
        }
      }
    }
  }

  .copyright {
    background-color: ${mainWhite};
    display: flex;
    justify-content: space-between;
    @media ${screen.xsmall} {
      padding: 12px 60px;
    }
    @media ${screen.small} {
      padding: 12px 80px;
    }

    @media ${screen.medium} {
      padding: 10px 7vw;
    }

    p {
      @media ${screen.xsmall} {
        font-size: 0.63rem;
      }
      @media ${screen.medium} {
        font-size: clamp(0.63rem, calc(0.4rem + 0.5vw), 1rem);
      }
      a {
        color: ${mainBlue};
      }
    }
  }
`;

const LargeFooter = () => {
  const { email, phone, address, social_media } = useStaticQuery(
    dataQuery
  ).content.data;

  const currentYear = new Date().getFullYear();
  return (
    <Wrapper>
      <div className="contact-details">
        <div className="each-col">
          <span className="each-col__lg-heading">Say hi</span>
        </div>
        <div className="each-col">
          <span className="each-col__sm-heading">
            Drop in for a visit
            <br />
            (well, maybe not right now)
          </span>

          <a
            className="each-col__link"
            href="https://www.google.com/maps/place/Handsome+Creative/@-33.8670148,151.207324,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae6a80ed540f:0x6e068d6288e42f45!8m2!3d-33.8670193!4d151.2095127"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Map Location"
          >
            {address}
          </a>
        </div>
        <div className="each-col">
          <span className="each-col__sm-heading">Flick us an email</span>
          <Obfuscate className="each-col__link" email={email} />
          <span className="each-col__sm-heading each-col__sm-heading--phone">
            Give us a buzz
          </span>
          <a
            className="each-col__link"
            href={`tel:${phone}`}
            aria-label="Call now"
          >
            {phone}
          </a>
          <ul className="each-col__social-list each-col__social-list--tablet">
            {social_media.map(item => (
              <li className="social-item" key={item.type}>
                <a
                  href={item.full_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit Social Media"
                >
                  {item.type === "Instagram" && <FaInstagram />}
                  {item.type === "Facebook" && <FaFacebookF />}
                  {item.type === "Twitter" && <FaTwitter />}
                  {item.type === "LinkedIn" && <FaLinkedinIn />}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="each-col each-col--desktop">
          <ul className="each-col__social-list">
            {social_media.map(item => (
              <li className="social-item" key={item.type}>
                <a
                  href={item.full_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit Social Media"
                  className={
                    item.type === "Instagram"
                      ? "instagram-link"
                      : item.type === "Facebook"
                      ? "facebook-link"
                      : item.type === "Twitter"
                      ? "twitter-link"
                      : item.type === "LinkedIn"
                      ? "linkedin-link"
                      : ""
                  }
                >
                  {item.type === "Instagram" && <FaInstagram />}
                  {item.type === "Facebook" && <FaFacebookF />}
                  {item.type === "Twitter" && <FaTwitter />}
                  {item.type === "LinkedIn" && <FaLinkedinIn />}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright&copy; {currentYear}</p>
        <p>
          Obviously built by{" "}
          <Link to="/built-by-handsome/" aria-label="Go to contact page">
            Handsome Creative
          </Link>
        </p>
      </div>
    </Wrapper>
  );
};

export default LargeFooter;

const dataQuery = graphql`
  {
    content: prismicGeneralDetails {
      data {
        email
        phone
        address
        social_media {
          type
          full_url
        }
      }
    }
  }
`;
