import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../css/normalize.css";
import "../css/global.css";
import styled from "styled-components";
import { siteWidth, mainBlack, screen } from "../components/common/variables";
import Nav from "../components/nav";
import SmallFooter from "../components/footer/small-screen";
import LargeFooter from "../components/footer/large-screen";

const Wrapper = styled.main`
  max-width: ${siteWidth};
  margin: 0 auto;
  background: ${(props) => props.colourNavbar};
  min-height: ${(props) => (props.isBuiltByPage ? "auto" : "100vh")};
  padding: 112px 0 0 0;
  color: ${mainBlack};
  overflow: hidden;
  @media ${screen.xsmall} {
    padding: 190px 0 0 0;
    min-height: 100vh;
  }
`;

// props use case below
// colourNavbar - colour prop for flexible sticky navbar background colour
// isContactPage - to hide/show footer
// is404Page - to hide/show footer
// isBuiltByPage - for css height condition
const Layout = ({
  children,
  colourNavbar,
  isContactPage,
  is404Page,
  isBuiltByPage,
}) => {
  // navbar handler
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [isNavbarShowing, setIsNavbarShowing] = useState(true);
  const [browserWidth, setBrowserWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  );

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [lastYPos, setLastYPos] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };

    const handleScroll = () => {
      const isTop = window.scrollY < 200;
      const yPos = window.scrollY;

      // check user if scrolling up
      const isUp = yPos < lastYPos;

      // show nav bar if is on the top of the page or if user scrolling up
      if (isTop || isUp) {
        setIsNavbarShowing(true);
      } else {
        setIsNavbarShowing(false);
      }

      setIsScrollingUp(isUp);
      setIsScrollTop(isTop);
      setLastYPos(yPos);
    };

    window.addEventListener("resize", handleResize, { passive: true });

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true });

      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, [lastYPos, isNavbarShowing]);

  const lockScroll =
    browserWidth > 650
      ? false
      : browserWidth < 650 && isMenuOpen
      ? true
      : false;
  // -- end navbar handler

  // ===================================

  // hide/show footer
  const isFooter = isContactPage ? false : is404Page ? false : true;

  return (
    <Wrapper
      colourNavbar={colourNavbar}
      isBuiltByPage={isBuiltByPage}
      id="layout-wrapper"
    >
      <Nav
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        isNavbarShowing={isNavbarShowing}
        isContactPage={isContactPage || is404Page}
        is404Page={is404Page}
        colourNavbar={colourNavbar}
        isScrollingUp={isScrollingUp}
        isScrollTop={isScrollTop}
        lockScroll={lockScroll}
      />
      {children}
      {isFooter && <SmallFooter />}
      {isFooter && <LargeFooter />}
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
