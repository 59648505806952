export const mainBlue = `#3AABE2`;
export const mainBlack = `#1A1A1A`;
export const mainGray = `#9D9D9D`;
export const lightGray = `#EFEFEF`;
export const mainWhite = `#FFFFFF`;
export const mainRed = `#FF0000`;

export const siteWidth = `1920px`;
export const innerWidth = ``;

export const transHover = `all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)`;

export const graphik = `graphik, sans-serif`;
export const larsseit = `larsseit, sans-serif`;
export const amatic = `'Amatic SC', cursive;`;

// media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // tablet vertical
  small: `(min-width: 1160px)`, // tablet horizontal
  medium: `(min-width: 1240px)`, // small laptops
  large: `(min-width: 1530px)`, // large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // laptop with short height
  xlarge: `(min-width: 1720px)`,
  xxlarge: `(min-width: 2000px)`, // large size desktop
  withCursor: `not all and (pointer: coarse)`, // device with cursor. not touch screen devices
};
