import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import {
  graphik,
  mainWhite,
  mainGray,
  mainBlue,
  mainBlack,
  lightGray,
  screen,
} from "../../components/common/variables";
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.footer`
  color: ${mainBlack};
  font-family: ${graphik};
  @media ${screen.xsmall} {
    display: none;
  }

  .contact-details {
    background-color: ${lightGray};
    padding: 16px 26px 26px 26px;

    &__link {
      a {
        color: ${mainBlack};
        white-space: pre-line;
        display: inline-block;
        font-size: 1rem;
        margin-top: 22px;
      }
    }

    &__social-list {
      display: flex;
      align-items: center;
      margin-top: 40px;

      .social-item {
        a {
          color: ${mainGray};
          display: block;
          font-size: 1.9rem;
          margin-right: 22px;
        }
      }
    }
  }

  .copyright {
    background-color: ${mainWhite};
    display: flex;
    padding: 16px 26px;
    @media ${screen.xsmall} {
      justify-content: space-between;
    }
    p {
      font-size: 0.65rem;
      a {
        color: ${mainBlue};
      }
    }

    .pipe-symbol {
      margin: 0 9px;
      font-size: 0.75rem;
      @media ${screen.xsmall} {
        display: none;
      }
    }
  }
`;

const SmallFooter = () => {
  const { email, phone, address, social_media } = useStaticQuery(
    dataQuery
  ).content.data;

  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <div className="contact-details">
        <p className="contact-details__link">
          <a href={`tel:${phone}`} aria-label="Call now">
            {phone}
          </a>
        </p>
        <p className="contact-details__link">
          <Obfuscate email={email} />
        </p>
        <p className="contact-details__link">
          <a
            href="https://www.google.com/maps/place/Handsome+Creative/@-33.8670148,151.207324,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae6a80ed540f:0x6e068d6288e42f45!8m2!3d-33.8670193!4d151.2095127"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit map location"
          >
            {address}
          </a>
        </p>

        <ul className="contact-details__social-list">
          {social_media.map(item => (
            <li className="social-item" key={item.type}>
              <a
                href={item.full_url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit social media"
                className={
                  item.type === "Instagram"
                    ? "instagram-link"
                    : item.type === "Facebook"
                    ? "facebook-link"
                    : item.type === "Twitter"
                    ? "twitter-link"
                    : item.type === "LinkedIn"
                    ? "linkedin-link"
                    : ""
                }
              >
                {item.type === "Instagram" && <FaInstagram />}
                {item.type === "Facebook" && <FaFacebookF />}
                {item.type === "Twitter" && <FaTwitter />}
                {item.type === "LinkedIn" && <FaLinkedinIn />}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="copyright">
        <p>Copyright&copy; {currentYear}</p>
        <span className="pipe-symbol">|</span>
        <p>
          Obviously built by{" "}
          <Link to="/built-by-handsome/" aria-label="Go to contact page">
            Handsome Creative
          </Link>
        </p>
      </div>
    </Wrapper>
  );
};

export default SmallFooter;

const dataQuery = graphql`
  {
    content: prismicGeneralDetails {
      data {
        email
        phone
        address
        social_media {
          type
          full_url
        }
      }
    }
  }
`;
