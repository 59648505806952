import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ScrollLocky } from "react-scroll-locky";
import LogoHandsome from "../../images/svg/handsome-creative-logo.svg";
import BurgerIcon from "../../images/svg/menu-burger.svg";
import CloseIcon from "../../images/svg/menu-close.svg";
import {
  mainGray,
  mainBlue,
  mainWhite,
  mainBlack,
  transHover,
  larsseit,
  graphik,
  screen,
  siteWidth,
} from "../../components/common/variables";
import HelloLogo from "../../images/svg/fixed-hello2.svg";
import { fadeIn } from "../../components/animated-heading/fadeIn";

const variantsContainer = {
  open: { opacity: 1, pointerEvents: "all" },
  close: { opacity: 0, pointerEvents: "none" },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const variantsOverlayContainer = {
  open: { y: "0" },
  close: { y: "-100%" },
};

const variantsOverlayList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsOverlayItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled.nav`
  position: absolute;
  background: ${(props) => props.colourNavbar};
  height: 190px;
  z-index: 50;

  .navbar {
    position: fixed;
    width: 100%;
    transition: all 0.15s ease-out;
    z-index: 11;
    background: ${(props) =>
      props.isMenuOpen ? mainBlue : props.colourNavbar};
    height: 72px;
    max-width: ${siteWidth};
    top: 0;
    opacity: ${(props) => (props.isScrollTop || props.isScrollingUp ? 1 : 0)};
    pointer-events: ${(props) =>
      props.isScrollTop || props.isScrollingUp ? "all" : "none"};
    @media ${screen.xsmall} {
      pointer-events: all;
      height: 120px;
      background: ${(props) => props.colourNavbar};
    }

    &__hidden-toggler {
      opacity: 0;
      display: block;
      width: 120px;
      height: 72px;
      @media ${screen.xsmall} {
        display: none;
      }
    }

    &__menu-icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      width: 20px;
      height: 20px;
      overflow: hidden;
      left: 26px;
      @media ${screen.xsmall} {
        left: 60px;
        width: 21px;
        height: 21px;
      }
      @media ${screen.small} {
        width: 27px;
        height: 27px;
        left: 80px;
      }
      @media ${screen.medium} {
        left: 7vw;
      }

      .opened-icon {
        transition: ${transHover};
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        transform: ${(props) =>
          !props.isMenuOpen ? `translateY(0)` : `translateY(-25px)`};

        .inner-burger {
          fill: ${(props) =>
            props.isMenuOpen || props.isContactPage ? mainWhite : mainGray};
          transition: ${transHover};
          @media ${screen.xsmall} {
            fill: ${(props) => (props.isContactPage ? mainWhite : mainGray)};
          }
        }
      }

      .closed-icon {
        transition: ${transHover};
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(26px);
        transform: ${(props) =>
          !props.isMenuOpen ? `translateY(27px)` : `translateY(0)`};

        .inner-close {
          fill: ${(props) =>
            props.isMenuOpen || props.isContactPage ? mainWhite : mainGray};
          stroke: ${(props) =>
            props.isMenuOpen || props.isContactPage ? mainWhite : mainGray};
          transition: ${transHover};
          @media ${screen.xsmall} {
            fill: ${(props) => (props.isContactPage ? mainWhite : mainGray)};
            stroke: ${(props) => (props.isContactPage ? mainWhite : mainGray)};
          }
        }
      }
    }

    .handsome-logo {
      display: block;
      position: absolute;
      top: 50%;
      right: 26px;
      transform: translate(0, -50%);
      opacity: ${(props) => (props.isScrollTop ? 1 : 0)};
      transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      z-index: ${(props) => (props.isScrollTop ? 9 : 1)};
      @media ${screen.xsmall} {
        right: 60px;
      }
      @media ${screen.small} {
        right: 80px;
      }
      @media ${screen.medium} {
        right: 7vw;
      }

      svg {
        width: 111px;
        @media ${screen.xsmall} {
          width: 116px;
        }
        @media ${screen.medium} {
          width: 162px;
        }

        .inner-logo {
          fill: ${(props) =>
            props.isMenuOpen || props.isContactPage ? mainWhite : mainGray};
          transition: fill 0.12s ease-out;
          @media ${screen.xsmall} {
            fill: ${(props) => (props.isContactPage ? mainWhite : mainGray)};
          }
        }
      }

      @media ${screen.withCursor} {
        &:hover .inner-logo {
          fill: ${(props) => (props.is404Page ? mainWhite : mainBlue)};
        }
      }
    }

    .hello-logo {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      right: 26px;
      transform: translate(0, -50%);
      z-index: ${(props) =>
        !props.isScrollTop && props.isScrollingUp ? 9 : 1};
      @media ${screen.xsmall} {
        right: 60px;
        top: calc(50% - 1px);
      }
      @media ${screen.small} {
        right: 80px;
      }
      @media ${screen.medium} {
        right: 7vw;
      }

      svg {
        display: block;
        opacity: ${(props) =>
          !props.isScrollTop && props.isScrollingUp ? 1 : 0};
        width: 53px;
        @media ${screen.xsmall} {
          width: 55px;
        }
        @media ${screen.medium} {
          width: 79px;
        }
        #letter-h {
          opacity: 0;
          animation: ${fadeIn} 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        }
        #letter-e {
          opacity: 0;
          animation: ${fadeIn} 0.2s 0.05s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        }
        #letter-l-1 {
          opacity: 0;
          animation: ${fadeIn} 0.2s 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        }
        #letter-l-2 {
          opacity: 0;
          animation: ${fadeIn} 0.2s 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        }
        #letter-o {
          opacity: 0;
          animation: ${fadeIn} 0.2s 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        }
        #dot {
          opacity: 0;
          animation: ${fadeIn} 0.2s 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        }

        .inner-logo {
          fill: ${(props) => (props.isMenuOpen ? mainWhite : mainGray)};
          transition: fill 0.25s ease-out;
          @media ${screen.xsmall} {
            fill: ${mainGray};
          }
        }
      }

      @media ${screen.withCursor} {
        &:hover .inner-logo {
          fill: ${(props) => (props.is404Page ? mainWhite : mainBlue)};
        }
      }
    }

    &__link-list {
      position: absolute;
      top: 50%;
      left: 260px;
      transform: translate(0, -50%);
      overflow: hidden;
      display: none;
      @media ${screen.xsmall} {
        left: 120px;
        display: flex;
      }
      @media ${screen.small} {
        left: 160px;
      }
      @media ${screen.medium} {
        left: 190px;
      }

      @media ${screen.large} {
        left: 200px;
      }
      @media ${screen.xxlarge} {
        left: 12vw;
      }

      li {
        a {
          color: ${(props) => (props.isContactPage ? mainWhite : mainGray)};
          font-family: ${graphik};
          font-size: 1.2rem;
          display: block;
          margin-right: 34px;
          transition: ${transHover};
          @media ${screen.xsmall} {
            margin-right: 30px;
            font-size: 1rem;
          }
          @media ${screen.small} {
            margin-right: 34px;
            font-size: 1.2rem;
          }
          @media ${screen.large} {
            margin-right: 40px;
            font-size: 1.25rem;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${(props) => (props.is404Page ? mainBlack : mainBlue)};
            }
          }
        }

        .active {
          color: ${mainBlue};
        }
      }
    }
  }

  .overlay-menu {
    background-color: ${mainBlue};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    transition: ${transHover};
    @media ${screen.xsmall} {
      display: none !important;
    }

    &__list {
      padding-top: 120px;
      padding-left: 26px;
      @media ${screen.xsmall} {
        display: none;
      }

      .menu-item {
        a {
          color: ${mainWhite};
          display: block;
          font-family: ${larsseit};
          font-size: 2.6rem;
          margin: 0 0 12px 0;
        }

        .active {
          color: ${mainBlack};
        }
      }
    }
  }
`;

// props use case below
// isMenuOpen - hide/show page links
// setMenuOpen - menu toggler
// isContactPage - for css colours style condition
// colourNavbar - setting navbar background colour
// isScrollingUp - hide/show handsome logo and hello
// isScrollTop - hide/show handsome logo and hello
// lockScroll - prevent user to scroll
// is404Page - for css colours style condition
const Nav = ({
  isMenuOpen,
  setMenuOpen,
  isContactPage,
  colourNavbar,
  isScrollingUp,
  isScrollTop,
  lockScroll,
  is404Page,
}) => {
  // menu toggler
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <Wrapper
      isMenuOpen={isMenuOpen}
      isContactPage={isContactPage}
      colourNavbar={colourNavbar}
      isScrollingUp={isScrollingUp}
      isScrollTop={isScrollTop}
      is404Page={is404Page}
    >
      <div className="navbar">
        <span onClick={toggleMenu} className="navbar__menu-icon">
          <BurgerIcon className="opened-icon" />
          <CloseIcon className="closed-icon" />
        </span>

        <span onClick={toggleMenu} className="navbar__hidden-toggler"></span>

        <Link to="/" className="handsome-logo" aria-label="Back to home page">
          <LogoHandsome />
        </Link>

        {isScrollingUp && (
          <Link className="hello-logo" to="/">
            <HelloLogo />
          </Link>
        )}

        <motion.div
          variants={variantsContainer}
          initial="close"
          animate={isMenuOpen ? "open" : "close"}
        >
          <motion.ul variants={variantsList} className="navbar__link-list">
            <motion.li variants={variantsItem}>
              <Link
                to="/"
                activeClassName="active"
                aria-label="Go to home page"
              >
                Home
              </Link>
            </motion.li>
            <motion.li variants={variantsItem}>
              <Link
                to="/about/"
                activeClassName="active"
                aria-label="Go to about page"
              >
                About
              </Link>
            </motion.li>
            <motion.li variants={variantsItem}>
              <Link
                to="/what-we-do/"
                activeClassName="active"
                aria-label="Go to what we do page"
              >
                What we do
              </Link>
            </motion.li>
            <motion.li variants={variantsItem}>
              <Link
                to="/our-work/"
                activeClassName="active"
                aria-label="Go to our work page"
              >
                Our work
              </Link>
            </motion.li>
            <motion.li variants={variantsItem}>
              <Link
                to="/contact/"
                activeClassName="active"
                aria-label="Go to contact page"
              >
                Contact
              </Link>
            </motion.li>
          </motion.ul>
        </motion.div>
      </div>

      <ScrollLocky
        // only lock scroll if on smaller device like tablet/mobile
        enabled={lockScroll}
        isolation={false}
      >
        <motion.div
          variants={variantsOverlayContainer}
          initial="close"
          animate={isMenuOpen ? "open" : "close"}
          className="overlay-menu"
        >
          <motion.ul
            variants={variantsOverlayList}
            className="overlay-menu__list"
          >
            <motion.li variants={variantsOverlayItem} className="menu-item">
              <Link
                to="/"
                activeClassName="active"
                aria-label="Go to home page"
              >
                Home
              </Link>
            </motion.li>
            <motion.li variants={variantsOverlayItem} className="menu-item">
              <Link
                to="/about"
                activeClassName="active"
                aria-label="Go to about page"
              >
                About us
              </Link>
            </motion.li>
            <motion.li variants={variantsOverlayItem} className="menu-item">
              <Link
                to="/what-we-do"
                activeClassName="active"
                aria-label="Go to what we do page"
              >
                What we do
              </Link>
            </motion.li>
            <motion.li variants={variantsOverlayItem} className="menu-item">
              <Link
                to="/our-work"
                activeClassName="active"
                aria-label="Go to our work page"
              >
                Our work
              </Link>
            </motion.li>
            <motion.li variants={variantsOverlayItem} className="menu-item">
              <Link
                to="/contact"
                activeClassName="active"
                aria-label="Go to contact page"
              >
                Contact
              </Link>
            </motion.li>
          </motion.ul>
        </motion.div>
      </ScrollLocky>
    </Wrapper>
  );
};

export default Nav;
