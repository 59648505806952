import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { organization, getWebPageSchema } from "../structured-data";

// props use case below
// description - fill meta description
// lang - language
// title - fill title tag
// image - fill meta image with url
// noIndex - is page to be indexed by google
// isHomePage - schema for organization to show on home page only
// webPageInfo - object for schema web page
function SEO({
  description,
  lang,
  title,
  image,
  noIndex,
  isHomePage,
  webPageInfo,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const defaultImg = "https://www.hellohandsome.com.au/handsome-creative.png";
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : defaultImg,
        },
        {
          property: `og:image:width`,
          content: "600",
        },
        {
          property: `og:image:height`,
          content: "600",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      {isHomePage && (
        <script type="application/ld+json">
          {JSON.stringify(organization)}
        </script>
      )}

      {webPageInfo && (
        <script type="application/ld+json">
          {JSON.stringify(
            getWebPageSchema(
              webPageInfo.name,
              webPageInfo.url,
              webPageInfo.description
            )
          )}
        </script>
      )}

      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
