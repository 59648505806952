export const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Handsome Creative",
  legalName: "HANDSOME CREATIVE PTY LTD",
  url: "https://www.hellohandsome.com.au",
  logo: "https://www.hellohandsome.com.au/handsome-creative.png",
  foundingDate: "2012",
  founders: [
    {
      "@type": "Person",
      name: "John Cassimatis",
    },
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Level 9/17 Castlereagh St",
    addressLocality: "Level 9/17 Castlereagh St",
    addressRegion: "Sydney",
    postalCode: "2000",
    addressCountry: "AU",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[0414 071 901]",
    email: "team@hellohandsome.com.au",
  },
  sameAs: [
    "https://twitter.com/aHandsomeTweet",
    "https://vimeo.com/hellohandsome",
    "https://www.pinterest.com.au/handsomecreate",
    "http://instagram.com/handsomecreative",
    "https://www.linkedin.com/company/handsome-creative",
    "https://www.facebook.com/handsomecreative",
  ],
};

export const getWebPageSchema = (name, url, description) => {
  return {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name,
    url,
    description,
    publisher: {
      "@type": "ProfilePage",
      name: "Handsome Creative",
    },
  };
};
